"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _ThumbUp = _interopRequireDefault(require("elevate-ui-icons/ThumbUp"));

var _ErrorOutline = _interopRequireDefault(require("elevate-ui-icons/ErrorOutline"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var iconMap = {
  error: _react.default.createElement(_ErrorOutline.default, null),
  info: _react.default.createElement(_ErrorOutline.default, null),
  success: _react.default.createElement(_ThumbUp.default, null),
  warning: _react.default.createElement(_ErrorOutline.default, null)
};

var Notification = function Notification(_ref) {
  var classes = _ref.classes,
      className = _ref.className,
      _ref$notification = _ref.notification,
      message = _ref$notification.message,
      title = _ref$notification.title,
      type = _ref$notification.type;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.root, classes[type], className)
  }, iconMap[type] && _react.default.createElement("div", {
    className: classes.icon
  }, iconMap[type]), _react.default.createElement("div", {
    className: classes.messageContainer
  }, _react.default.createElement("div", {
    className: classes.title
  }, title), _react.default.createElement("div", {
    className: classes.message
  }, message)));
};

var styles = function styles(theme) {
  return {
    "@keyframes slideIn": {
      from: {
        transform: "translateX(100%)"
      },
      to: {
        transform: "translateX(0)"
      }
    },
    root: {
      display: "flex",
      alignItems: "center",
      width: "280px",
      backgroundColor: "rgba(255, 255, 255, 0.98)",
      padding: "16px 12px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.06)",
      borderRadius: "6px",
      borderLeftWidth: "6px",
      borderLeftStyle: "solid",
      margin: "8px",
      animationName: "slideIn",
      animationDuration: "600ms",
      transition: theme.transitions["default"]
    },
    error: {
      borderLeftColor: theme.colors.primary["800"]
    },
    info: {
      borderLeftColor: theme.colors.tertiary["700"]
    },
    warning: {
      borderLeftColor: theme.colors.yellow["600"]
    },
    success: {
      borderLeftColor: theme.colors.green["700"]
    },
    icon: {
      color: "#B3BAC1",
      marginRight: "12px"
    },
    title: {
      fontWeight: 600,
      fontSize: "16px",
      letterSpacing: ".45px",
      color: "#4b565f",
      paddingBottom: "4px"
    },
    message: {
      fontSize: "14px",
      color: "#60686F",
      letterSpacing: ".45px"
    }
  };
};

var _default = (0, _withStyles.default)(styles)(Notification);

exports.default = _default;