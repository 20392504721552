"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * A component used for showing important information such as success messages, warnings, or errors.
 */


var Alert = function Alert(_ref) {
  var children = _ref.children,
      classes = _ref.classes,
      className = _ref.className,
      color = _ref.color,
      Element = _ref.element,
      icon = _ref.icon,
      theme = _ref.theme,
      rest = _objectWithoutProperties(_ref, ["children", "classes", "className", "color", "element", "icon", "theme"]);

  return (// $FlowIgnore -- it doesn't understand the element: Element
    _react.default.createElement(Element, _extends({
      className: (0, _classnames.default)(classes.root, className)
    }, rest), icon && _react.default.createElement("div", {
      className: classes.icon
    }, icon), _react.default.createElement("div", {
      className: classes.children
    }, children))
  );
};

Alert.defaultProps = {
  element: "div",
  color: "error"
};

var styles = function styles(theme) {
  return {
    root: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: function backgroundColor(props) {
        return theme.alertColors[props.color] && theme.alertColors[props.color].backgroundColor || "#fff";
      },
      borderRadius: "6px",
      borderColor: function borderColor(props) {
        return theme.alertColors[props.color] && theme.alertColors[props.color].borderColor || "#eee";
      },
      borderWidth: "2px",
      borderStyle: "solid"
    },
    icon: {
      flexShrink: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: function color(props) {
        return theme.alertColors[props.color] && theme.alertColors[props.color].color || "#000";
      },
      padding: "10px 0 10px 16px"
    },
    children: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
      color: function color(props) {
        return theme.alertColors[props.color] && theme.alertColors[props.color].color || "#000";
      },
      fontSize: "14px",
      letterSpacing: ".3px",
      lineHeight: "20px",
      padding: "16px 24px",
      // Override default link styling
      "& a": {
        color: function color(props) {
          return theme.alertColors[props.color] && theme.alertColors[props.color].color || "#000";
        },
        wordBreak: "break-word" // long URLs or emails should break+wrap to the next line

      },
      "& p": {
        color: function color(props) {
          return theme.alertColors[props.color] && theme.alertColors[props.color].color || "#4b565f";
        }
      }
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiAlert"
})(Alert);

exports.default = _default;