"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineReducers = exports.singleOpen = exports.preventClose = void 0;

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var preventClose = function preventClose(state, changes) {
  return changes.type === "closing" && state.openIndexes.length < 2 ? _objectSpread({}, changes, {
    openIndexes: state.openIndexes
  }) : changes;
};

exports.preventClose = preventClose;

var singleOpen = function singleOpen(state, changes) {
  return changes.type === "opening" ? _objectSpread({}, changes, {
    openIndexes: changes.openIndexes.slice(-1)
  }) : changes;
};

exports.singleOpen = singleOpen;

var combineReducers = function combineReducers() {
  for (var _len = arguments.length, reducers = new Array(_len), _key = 0; _key < _len; _key++) {
    reducers[_key] = arguments[_key];
  }

  return function (state, changes) {
    return reducers.reduce(function (acc, reducer) {
      return reducer(state, acc);
    }, changes);
  };
};

exports.combineReducers = combineReducers;