"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Button = _interopRequireDefault(require("../Button"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ActionButtons = function ActionButtons(_ref) {
  var actions = _ref.actions,
      classes = _ref.classes,
      selection = _ref.selection;
  return _react.default.createElement("div", {
    className: classes.root
  }, selection && selection.length > 0 && actions.map(function (action, i) {
    return _react.default.createElement(_Button.default, {
      key: i,
      type: "button",
      onClick: function onClick() {
        return action.callback(selection);
      }
    }, action.title);
  }));
}; // eslint-disable-next-line


var styles = function styles(theme) {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
      minHeight: "60px"
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiActionButtons"
})(ActionButtons);

exports.default = _default;