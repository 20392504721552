"use strict";

var _color = _interopRequireDefault(require("color"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.generatePalette = function (hex) {
  var color = {
    "050": (0, _color.default)(hex).lighten(0.5).string(),
    "100": (0, _color.default)(hex).lighten(0.4).string(),
    "200": (0, _color.default)(hex).lighten(0.3).string(),
    "300": (0, _color.default)(hex).lighten(0.2).string(),
    "400": (0, _color.default)(hex).lighten(0.1).string(),
    "500": hex,
    "600": (0, _color.default)(hex).darken(0.1).string(),
    "700": (0, _color.default)(hex).darken(0.2).string(),
    "800": (0, _color.default)(hex).darken(0.3).string(),
    "900": (0, _color.default)(hex).darken(0.4).string()
  };
  return color;
};