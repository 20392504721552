"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactJss = _interopRequireDefault(require("react-jss"));

var _merge = _interopRequireDefault(require("lodash/merge"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var withStyles = function withStyles(styles, options) {
  return (0, _reactJss.default)(function (theme) {
    if (options && options.name && theme && theme.overrides && theme.overrides[options.name]) {
      return (0, _merge.default)(styles(theme), theme.overrides[options.name]);
    }

    return styles(theme);
  });
};

var _default = withStyles;
exports.default = _default;