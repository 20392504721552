"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _Close = _interopRequireDefault(require("elevate-ui-icons/Close"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Tag = function Tag(props) {
  var classes = props.classes,
      className = props.className,
      tag = props.tag,
      onRemove = props.onRemove;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.root, className)
  }, _react.default.createElement("span", {
    className: classes.text
  }, tag.label), _react.default.createElement("button", {
    type: "button",
    className: classes.button,
    onClick: function onClick(e) {
      e.stopPropagation();
      e.preventDefault();
      onRemove(tag);
    }
  }, _react.default.createElement(_Close.default, {
    size: 18
  })));
};

var styles = function styles(theme) {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      border: "1px solid ".concat(theme.colors.gray900),
      borderRadius: theme.globalBorderRadius,
      margin: "2px"
    },
    text: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "inherit",
      whiteSpace: "nowrap",
      padding: "2px 2px 2px 8px"
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "inherit",
      padding: "2px",
      margin: "2px"
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiTag"
})(Tag);

exports.default = _default;