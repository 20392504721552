"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* Animated SVG thanks to @sherb at http://samherbert.net/svg-loaders/ */


var FullscreenLoader = function FullscreenLoader(_ref) {
  var classes = _ref.classes,
      error = _ref.error,
      isLoading = _ref.isLoading,
      pastDelay = _ref.pastDelay,
      text = _ref.text,
      timedOut = _ref.timedOut;

  if (isLoading) {
    // While our other component is loading...
    if (timedOut) {
      // In case we've timed out loading our other component.
      return _react.default.createElement("div", null, "Loader timed out!");
    } else if (pastDelay) {
      // Display a loading screen after a set delay.
      return _react.default.createElement("div", {
        className: classes.root
      }, _react.default.createElement("svg", {
        width: "118",
        height: "70",
        viewBox: "0 0 135 140",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "#f15953"
      }, _react.default.createElement("rect", {
        y: "10",
        width: "15",
        height: "120",
        rx: "6"
      }, _react.default.createElement("animate", {
        attributeName: "height",
        begin: "0.5s",
        dur: "1s",
        values: "120;110;100;90;80;70;60;50;40;140;120",
        calcMode: "linear",
        repeatCount: "indefinite"
      }), _react.default.createElement("animate", {
        attributeName: "y",
        begin: "0.5s",
        dur: "1s",
        values: "10;15;20;25;30;35;40;45;50;0;10",
        calcMode: "linear",
        repeatCount: "indefinite"
      })), _react.default.createElement("rect", {
        x: "30",
        y: "10",
        width: "15",
        height: "120",
        rx: "6"
      }, _react.default.createElement("animate", {
        attributeName: "height",
        begin: "0.25s",
        dur: "1s",
        values: "120;110;100;90;80;70;60;50;40;140;120",
        calcMode: "linear",
        repeatCount: "indefinite"
      }), _react.default.createElement("animate", {
        attributeName: "y",
        begin: "0.25s",
        dur: "1s",
        values: "10;15;20;25;30;35;40;45;50;0;10",
        calcMode: "linear",
        repeatCount: "indefinite"
      })), _react.default.createElement("rect", {
        x: "60",
        width: "15",
        height: "140",
        rx: "6"
      }, _react.default.createElement("animate", {
        attributeName: "height",
        begin: "0s",
        dur: "1s",
        values: "120;110;100;90;80;70;60;50;40;140;120",
        calcMode: "linear",
        repeatCount: "indefinite"
      }), _react.default.createElement("animate", {
        attributeName: "y",
        begin: "0s",
        dur: "1s",
        values: "10;15;20;25;30;35;40;45;50;0;10",
        calcMode: "linear",
        repeatCount: "indefinite"
      })), _react.default.createElement("rect", {
        x: "90",
        y: "10",
        width: "15",
        height: "120",
        rx: "6"
      }, _react.default.createElement("animate", {
        attributeName: "height",
        begin: "0.25s",
        dur: "1s",
        values: "120;110;100;90;80;70;60;50;40;140;120",
        calcMode: "linear",
        repeatCount: "indefinite"
      }), _react.default.createElement("animate", {
        attributeName: "y",
        begin: "0.25s",
        dur: "1s",
        values: "10;15;20;25;30;35;40;45;50;0;10",
        calcMode: "linear",
        repeatCount: "indefinite"
      })), _react.default.createElement("rect", {
        x: "120",
        y: "10",
        width: "15",
        height: "120",
        rx: "6"
      }, _react.default.createElement("animate", {
        attributeName: "height",
        begin: "0.5s",
        dur: "1s",
        values: "120;110;100;90;80;70;60;50;40;140;120",
        calcMode: "linear",
        repeatCount: "indefinite"
      }), _react.default.createElement("animate", {
        attributeName: "y",
        begin: "0.5s",
        dur: "1s",
        values: "10;15;20;25;30;35;40;45;50;0;10",
        calcMode: "linear",
        repeatCount: "indefinite"
      }))), _react.default.createElement("div", {
        className: classes.text
      }, text || "Loading…"));
    } else {
      // Don't flash "Loading..." when we don't need to.
      return null;
    }
  } else if (error) {
    // If we aren't loading, maybe
    return _react.default.createElement("div", null, "Error! Component failed to load. Try refreshing the page.");
  } // This case shouldn't happen... but we'll return null anyways.


  return null;
};

var styles = function styles(theme) {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: "1"
    },
    text: {
      color: theme.colors.gray700,
      fontSize: "16px",
      fontWeight: "500",
      marginTop: "32px"
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiFullscreenLoader"
})(FullscreenLoader);

exports.default = _default;