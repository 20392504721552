"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _SearchInput = _interopRequireDefault(require("../Input/SearchInput"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var FilterComponent = function FilterComponent(_ref) {
  var filter = _ref.filter,
      onChange = _ref.onChange;
  return _react.default.createElement(_SearchInput.default, {
    onChange: onChange,
    value: filter ? filter.value : ""
  });
};

var _default = FilterComponent;
exports.default = _default;