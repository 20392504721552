"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _Notification = _interopRequireDefault(require("./Notification"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Notifications = function Notifications(_ref) {
  var classes = _ref.classes,
      className = _ref.className,
      notifications = _ref.notifications;

  if (!(notifications && notifications.length)) {
    return null;
  }

  return _react.default.createElement("div", {
    className: (0, _classnames.default)(classes.root, className)
  }, notifications.map(function (notification) {
    return _react.default.createElement(_Notification.default, {
      key: notification.id,
      notification: notification
    });
  }));
};

var styles = function styles(theme) {
  return {
    root: {
      position: "fixed",
      top: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      zIndex: theme.zIndex.notifications || 99
    }
  };
};

var _default = (0, _withStyles.default)(styles)(Notifications);

exports.default = _default;