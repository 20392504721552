"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * A styled component for framing content and other components.
 */


var Paper = function Paper(_ref) {
  var children = _ref.children,
      classes = _ref.classes,
      className = _ref.className,
      Element = _ref.element,
      theme = _ref.theme,
      _ref$withPadding = _ref.withPadding,
      withPadding = _ref$withPadding === void 0 ? true : _ref$withPadding,
      rest = _objectWithoutProperties(_ref, ["children", "classes", "className", "element", "theme", "withPadding"]);

  return _react.default.createElement(Element, _extends({
    className: (0, _classnames.default)(classes.root, withPadding && classes.withPadding, className)
  }, rest), children);
};

Paper.defaultProps = {
  element: "div"
};

var styles = function styles(theme) {
  return {
    root: {
      width: "100%",
      height: "auto",
      background: theme.colors.white,
      border: "1px solid ".concat(theme.colors.gray200),
      borderRadius: theme.globalBorderRadius
    },
    withPadding: _objectSpread({}, theme.globalPadding)
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiPaper"
})(Paper);

exports.default = _default;