"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var typeElementMap = {
  title: "h1",
  body: "p",
  body2: "p",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  heading5: "h5",
  subHeading: "h6"
};
/**
 * Renders a component used for displaying headings or body copy.
 */

var Typography = function Typography(_ref) {
  var children = _ref.children,
      color = _ref.color,
      classes = _ref.classes,
      className = _ref.className,
      elementProp = _ref.element,
      gutterTop = _ref.gutterTop,
      gutterBottom = _ref.gutterBottom,
      theme = _ref.theme,
      type = _ref.type,
      rest = _objectWithoutProperties(_ref, ["children", "color", "classes", "className", "element", "gutterTop", "gutterBottom", "theme", "type"]);

  var Element = elementProp || typeElementMap[type] || "span";
  return _react.default.createElement(Element, _extends({
    className: (0, _classnames.default)(classes.root, classes[type], gutterTop && classes.gutterTop, gutterBottom && classes.gutterBottom, className)
  }, rest), children);
};

var styles = function styles(theme) {
  return {
    root: {
      width: "100%"
    },
    title: _defineProperty({
      fontSize: "32px",
      lineHeight: "48px",
      fontWeight: "600",
      color: function color(props) {
        return props.color || "#2E2E35";
      }
    }, theme.breakpoints(600), {
      fontSize: "36px"
    }),
    heading2: _defineProperty({
      fontSize: "28px",
      lineHeight: "44px",
      fontWeight: "600",
      color: function color(props) {
        return props.color || "#2E2E35";
      }
    }, theme.breakpoints(600), {
      fontSize: "32px"
    }),
    heading3: _defineProperty({
      fontSize: "24px",
      lineHeight: "40px",
      fontWeight: "600",
      color: function color(props) {
        return props.color || "#2E2E35";
      }
    }, theme.breakpoints(600), {
      fontSize: "28px"
    }),
    heading4: _defineProperty({
      fontSize: "20px",
      lineHeight: "36px",
      fontWeight: "600",
      color: function color(props) {
        return props.color || "#2E2E35";
      }
    }, theme.breakpoints(600), {
      fontSize: "24px"
    }),
    heading5: _defineProperty({
      fontSize: "18px",
      lineHeight: "32px",
      fontWeight: "600",
      color: function color(props) {
        return props.color || "#2E2E35";
      }
    }, theme.breakpoints(600), {
      fontSize: "21px"
    }),
    subHeading: _defineProperty({
      fontSize: "16px",
      lineHeight: "30px",
      fontWeight: "600",
      color: function color(props) {
        return props.color || "#2E2E35";
      }
    }, theme.breakpoints(600), {
      fontSize: "18px"
    }),
    subHeading2: {
      color: theme.colors.gray["600"],
      fontSize: "13px",
      fontWeight: "600",
      textTransform: "uppercase",
      letterSpacing: ".8px"
    },
    body: _defineProperty({
      fontSize: "18px",
      lineHeight: "28px",
      color: function color(props) {
        return props.color || "#4b565f";
      }
    }, theme.breakpoints(600), {
      fontSize: "20px"
    }),
    body2: _defineProperty({
      fontSize: "14px",
      lineHeight: "26px",
      color: function color(props) {
        return props.color || "#11181e";
      }
    }, theme.breakpoints(600), {
      fontSize: "16px"
    }),
    gutterBottom: {
      marginBottom: function marginBottom(props) {
        return props.type === "body" ? "1.5em" : "0.35em";
      }
    },
    gutterTop: {
      marginTop: "0.35em"
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiTypography"
})(Typography);

exports.default = _default;