"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * A component meant to be wrapped in `<TabNavigation />` that allows you to create a "tab-style" navigation.
 */


var Tab = function Tab(_ref) {
  var _classNames;

  var classes = _ref.classes,
      element = _ref.element,
      children = _ref.children,
      className = _ref.className,
      isActive = _ref.isActive,
      rest = _objectWithoutProperties(_ref, ["classes", "element", "children", "className", "isActive"]);

  var Element = element || "button";
  return _react.default.createElement(Element, _extends({}, rest, {
    className: (0, _classnames.default)((_classNames = {}, _defineProperty(_classNames, classes.root, true), _defineProperty(_classNames, classes.active, isActive), _defineProperty(_classNames, "className", className), _classNames))
  }), children);
};

var styles = function styles(theme) {
  return {
    root: _defineProperty({
      padding: "24px",
      fontSize: "15px",
      fontWeight: "600",
      letterSpacing: ".5px",
      lineHeight: 1,
      textTransform: "uppercase",
      borderLeft: "4px solid ".concat(theme.colors.gray["100"]),
      transition: theme.transitions.default,
      color: theme.colors.gray["600"],
      textDecoration: "none",
      "&:hover": {
        color: theme.colors.black
      }
    }, theme.breakpoints(670), {
      fontSize: "14px",
      padding: "12px 24px",
      borderBottom: "2px solid ".concat(theme.colors.gray["100"]),
      borderLeft: "none"
    }),
    active: _defineProperty({
      color: theme.colors.black,
      borderLeft: "4px solid ".concat(theme.colors.tertiary["300"])
    }, theme.breakpoints(670), {
      borderBottom: "2px solid ".concat(theme.colors.tertiary["300"]),
      borderLeft: "none"
    })
  };
};

var _default = (0, _withStyles.default)(styles)(Tab);

exports.default = _default;