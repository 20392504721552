"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Validation = function Validation(_ref) {
  var classes = _ref.classes,
      className = _ref.className,
      color = _ref.color,
      error = _ref.error,
      theme = _ref.theme,
      rest = _objectWithoutProperties(_ref, ["classes", "className", "color", "error", "theme"]);

  return _react.default.createElement("div", _extends({
    className: (0, _classnames.default)(classes.root, className)
  }, rest), error);
};

Validation.defaultProps = {
  color: "error"
};

var styles = function styles(theme) {
  return {
    root: {
      display: "inline-flex",
      fontSize: "14px",
      lineHeight: "18px",
      color: function color(props) {
        return theme.alertColors[props.color].color;
      },
      backgroundColor: function backgroundColor(props) {
        return theme.alertColors[props.color].backgroundColor;
      },
      borderRadius: "2px",
      borderColor: function borderColor(props) {
        return theme.alertColors[props.color].borderColor;
      },
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "4px",
      padding: "2px 8px"
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiValidation"
})(Validation);

exports.default = _default;