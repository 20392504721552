"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _withStyles = _interopRequireDefault(require("../withStyles"));

var _get = _interopRequireDefault(require("lodash/get"));

var _Scaffold = _interopRequireDefault(require("../Scaffold"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}
/**
 * A component that renders a <textarea> for use in forms.
 */


var Textarea = function Textarea(_ref) {
  var classes = _ref.classes,
      className = _ref.className,
      field = _ref.field,
      _ref$form = _ref.form,
      touched = _ref$form.touched,
      errors = _ref$form.errors,
      id = _ref.id,
      label = _ref.label,
      theme = _ref.theme,
      type = _ref.type,
      _ref$withScaffold = _ref.withScaffold,
      withScaffold = _ref$withScaffold === void 0 ? true : _ref$withScaffold,
      rest = _objectWithoutProperties(_ref, ["classes", "className", "field", "form", "id", "label", "theme", "type", "withScaffold"]);

  return withScaffold ? _react.default.createElement(_Scaffold.default, {
    id: id,
    label: label,
    error: (0, _get.default)(touched, field.name) && (0, _get.default)(errors, field.name)
  }, _react.default.createElement("textarea", _extends({
    id: id,
    className: (0, _classnames.default)(classes.root, classes.textarea, className)
  }, field, rest))) : _react.default.createElement("textarea", _extends({
    id: id,
    className: (0, _classnames.default)(classes.root, classes.textarea, className)
  }, field, rest));
};

Textarea.defaultProps = {
  tabIndex: "0"
};

var styles = function styles(theme) {
  return {
    root: {
      display: "block",
      width: "100%",
      height: "auto",
      color: theme.typography.bodyColor,
      fontFamily: "inherit",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "20px",
      backgroundColor: theme.colors.white,
      border: "1px solid ".concat(theme.colors.gray300),
      padding: "8px 12px",
      boxShadow: "none",
      // Reset default inputs for mozilla
      "-webkit-appearance": "none",
      // Reset default browser styles
      "-moz-appearance": "none",
      // Reset default browser styles
      "&:focus": {
        outline: "none",
        // Disable default focus glow
        boxShadow: theme.globalBoxShadow // Add back focus style

      },
      "&:disabled": {
        cursor: "not-allowed"
      }
    },
    textarea: {
      minHeight: "120px",
      resize: "vertical"
    }
  };
};

var _default = (0, _withStyles.default)(styles, {
  name: "EuiTextarea"
})(Textarea);

exports.default = _default;